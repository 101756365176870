import React, { useEffect, useRef, useState } from 'react';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import LogoHeader from '../../components/LogoHeader/LogoHeader';
import Title from '../../components/Title/Title';
import CustomInput from '../../components/CustomInput/CustomInput';
import CustomPasswordInput from '../../components/CustomInput/CustomPasswordInput';
// import CustomCheckBox from '../../components/CustomInput/CustomCheckBox';
import PrimaryButton from '../../components/CustomButtons/PrimaryButton';
import Spacer from '../../components/Spacer/Spacer';
import { styled } from 'styled-components';
import { Colors } from '../../utils/configs/Colors';
import GoWellFooter from '../../components/GoWellFooter/GoWellFooter';
import CustomPhoneInput from '../../components/CustomInput/CustomPhoneInput';
import {
	UserSignUpProps,
	UserSignUpPropsPattern,
	UserSignUpPropsValid,
} from '../../types_and_interfaces/user-signup';
import {
	IsSelectUserExsist,
	SubmitSignUp,
} from '../../utils/services/api/signup';
import { useLocation, useNavigate } from 'react-router-dom';
import ErrorText from '../../components/CustomText/ErrorText';
import { ContextConstants } from '../../utils/services/storage/constants';
import { CommonEncryption } from '../../utils/helper/commonEncryption';
import { useCommonContext } from '../../context/CommonContext';
import {
	FlowTypes,
	ToastTypes,
} from '../../types_and_interfaces/common-context';
import CustomCheckBox from '../../components/CustomInput/CustomCheckBox';
import TextButton from '../../components/CustomButtons/TextButton';
import TermsAndConditionPopup from '../../components/Popup/TermsAndConditionPopup';
import ProfileInUsePopup from './ProfileInUsePopup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input/mobile'

const getRegex = (target: keyof UserSignUpProps): RegExp => {
	let reg = new RegExp(/w+/);
	switch (target) {
		case 'UserId':
			reg = UserSignUpPropsPattern.UserId;
			break;
		case 'FirstName':
			reg = UserSignUpPropsPattern.FirstName;
			break;
		case 'LastName':
			reg = UserSignUpPropsPattern.LastName;
			break;
		case 'MobileNumber':
			reg = UserSignUpPropsPattern.MobileNumber;
			break;
		case 'Email':
			reg = UserSignUpPropsPattern.Email;
			break;
		case 'Password':
			reg = UserSignUpPropsPattern.Password;
			break;
		default:
			reg = new RegExp(/w+/);
			break;
	}
	return reg;
};

const schema = yup.object({
	UserId: yup.string(),
	FirstName: yup.string().matches(getRegex('FirstName')).required(),
	LastName: yup.string().matches(getRegex('LastName')).required(),
	MobileNumber: yup.string().matches(getRegex('MobileNumber')).required(),
	Email: yup.string().matches(getRegex('Email')).required(),
	Password: yup.string().matches(getRegex('Password')).required(),
});

function SignUp() {
	const [TACState, setTACState] = useState<boolean>(false);
	const [agreedTAC, setAgreedTAC] = useState<boolean>(false);
	const [initialLoad, setInitialLoad] = useState<boolean>(true);
	const [buttonClicked, setButtonClicked] = useState<boolean>(false);

	const { addToast, updateFlow } = useCommonContext();

	const search = useLocation().search;
	const GoWellUserId = new URLSearchParams(search).get('userid') || '';
	const GoWellSignupRequestId =
		new URLSearchParams(search).get('gowell-signup-request-id') || '';

	const [existingUser, setExistingUser] = useState<UserSignUpProps>();

	useEffect(() => {
		setInitialLoad(true);
		updateFlow(FlowTypes.SignUp);
		var gwuid = window.localStorage.getItem('GoWellUserId') || '';
		window.localStorage.setItem('GoWellUserId', GoWellUserId || gwuid);
		window.localStorage.setItem('GoWellSignupRequestId', GoWellSignupRequestId);

		if(window.sessionStorage.getItem('SignUpUser')){
			setExistingUser(JSON.parse(window.sessionStorage.getItem('SignUpUser')||''));
			setAgreedTAC(true);
		}

		callNativeApp();
	}, []);

	const {
		trigger,
		handleSubmit,
		watch,
		control,
		formState: { errors },
	} = useForm<UserSignUpProps>({
		mode: 'all',
		values: {
			UserId: '',
			FirstName: existingUser?.FirstName || '',
			LastName: existingUser?.LastName || '',
			MobileNumber: existingUser?.MobileNumber || '',
			Email: existingUser?.Email || '',
			Password: existingUser?.Password || '',
			GoWellUserId: '',
		},
		resolver: yupResolver(schema),
	});

	const [popupState, setPopupState] = useState<boolean>(false);

	const callNativeApp = () => {
		// try {
        //     (window as any).webkit.messageHandlers.callbackHandler.postMessage("signup");
        // } catch(err) {
		// 	console.log(err)
        //     console.log('The native context does not exist yet');
		// 	alert((err as any).message);
        // }
	}

	const openPopup = () => {
		setPopupState(true);
	};

	const goToSupport = () => {
		navigate('/accountSupport');
	};

	const submitForm = (formData: UserSignUpProps) => {
		SubmitSignUp({
			...formData,
			Password: CommonEncryption(formData.Password),
			GoWellUserId: window.localStorage.getItem('GoWellUserId') || '',
			GoWellSignupRequestId:
				window.localStorage.getItem('GoWellSignupRequestId') || '',
		})
			.then((data) => {
				if (data?.isSuccess) {
					//add user object to session storage
					window.sessionStorage.setItem('SignUpUser', JSON.stringify({...formData, UserId: data.data}));
					goToEmailConfirm(data.data, formData.Email, formData.MobileNumber);
				} else {
					if (data?.code === 'EXISTS_WITH_SAME') {
						addToast({
							type: ToastTypes.Error,
							message: data.message,
						});
					} else if (data?.code === 'EXISTS_WITH_DIFF') {
						openPopup();
					}
				}
			})
			.catch((err) => {
				//TODO: redirect to technical error page /errorTechIssue
				console.log(err);
				navigate('/errorTechIssue');
			});
	};

	const navigate = useNavigate();

	const goToEmailConfirm = (
		userId: string,
		Email: string,
		MobileNumber: string
	) => {
		navigate('/emailConfirmationSignUp?userId=' + userId, {
			state: { email: Email, mobileNumber: MobileNumber },
		});
	};

	const goToLogin = () => {
		navigate('/login');
	};

	const openTAC = () => {
		setTACState(true);
	};

	const closeTAC = () => {
		setTACState(false);
	};

	const confirmTAC = () => {
		setAgreedTAC(true);
		closeTAC();
	};

	const cancelTAC = () => {
		setAgreedTAC(false);
		closeTAC();
	};

	return (
		<PageWrapper>
			<ContentContainer>
				<LogoHeader />
				<Title
					styles={{
						text: {
							color: Colors.grey.lightGrey2,
						},
					}}
					title='Join our exclusive community'
				/>
				<Controller
					control={control}
					name='FirstName'
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<CustomInput
							title='First name'
							value={value}
							onChange={onChange}
							error={!!error}
							errorComp={
								<ErrorText>
									{value === ''
										? 'This field is required'
										: 'First name should contain at least 2 letters'}
								</ErrorText>
							}
						/>
					)}
				/>
				<Controller
					control={control}
					name='LastName'
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<CustomInput
							title='Last name'
							value={value}
							onChange={onChange}
							error={!!error}
							errorComp={
								<ErrorText>
									{value === ''
										? 'This field is required'
										: 'Last name should contain at least 2 letters'}
								</ErrorText>
							}
						/>
					)}
				/>
				<Controller
					control={control}
					name='MobileNumber'
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<CustomPhoneInput
							title='Mobile number'
							value={value}
							onChange={onChange}
							error={!!error}
							errorComp={
								<ErrorText>
									{ !initialLoad && value === ''
										? 'This field is required'
										: value !== '' ? 'Please enter a valid phone number' : null}
								</ErrorText>
							}
						/>
					)}
				/>
				<Controller
					control={control}
					name='Email'
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<CustomInput
							title='Email'
							value={value}
							onChange={onChange}
							error={!!error}
							errorComp={
								<ErrorText>
									{value === ''
										? 'This field is required'
										: 'The email format is incorrect.'}
								</ErrorText>
							}
						/>
					)}
				/>
				<Controller
					control={control}
					name='Password'
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<>
							<CustomPasswordInput
								title='Create password'
								enablePasswordToggle
								value={value}
								onChange={onChange}
								error={!!error}
								getErrorState={(state) => {
									if (value !== '') trigger('Password', { shouldFocus: state });
								}}
							/>
							{!!error && value === '' ? (
								<ErrorText>This field is required</ErrorText>
							) : !!error  ? <ErrorText>The password format is incorrect.</ErrorText> : null}
						</>
					)}
				/>
				<CustomCheckBox
					title={
						<>
							By ticking this box you are agreeing with our
							<br />
							<TextButton
								width='auto'
								color={Colors.primary.white}
								padding='0px'
								onClick={openTAC}>
								Terms and Conditions
							</TextButton>
						</>
					}
					value={agreedTAC}
					onChange={(e) => {
						setAgreedTAC(e?.target?.checked || false);
					}}
				/>
				{(initialLoad === false &&
				agreedTAC === false) ? (
					<ErrorText>You need to accept the terms and conditions</ErrorText>
				) : null}
				<Spacer space={{ x: '100%', y: '88px' }} />
				<PrimaryButton
					// onClick={submitForm}
					onClick={handleSubmit((data) => {
						setInitialLoad(false);
						setButtonClicked(true);
						if(agreedTAC && isValidPhoneNumber(data.MobileNumber)){
							submitForm(data);
						}
					},(errors) => {
						setInitialLoad(false);
						setButtonClicked(true);
					})}
					disabled={
						initialLoad === false && (Object.keys(errors).length > 0 ||
						agreedTAC === false)
					}>
					Sign Up
				</PrimaryButton>
				<SwitcherContainer>
					<SwitcherText>Already have an account?&nbsp;</SwitcherText>
					<SwitcherLink onClick={goToLogin}>&nbsp;Log In</SwitcherLink>
				</SwitcherContainer>
				<Spacer space={{ x: '100%', y: '51px' }} />
				<ProfileInUsePopup
					show={popupState}
					confirmAction={goToLogin}
					cancelAction={goToSupport}
				/>
				<TermsAndConditionPopup
					show={TACState}
					hide={closeTAC}
					confirmAction={confirmTAC}
					cancelAction={cancelTAC}
				/>
			</ContentContainer>
			<GoWellFooter />
		</PageWrapper>
	);
}

export default SignUp;

const ContentContainer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: flex-start;
	overflow-y: scroll;
	margin-left: -40px;
	margin-right: -40px;
	padding-left: 40px;
	padding-right: 40px;
`;

const SwitcherContainer = styled.div`
	display: block;
	width: 100%;
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-top: 24px;
`;

const SwitcherText = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: ${Colors.grey.lightGrey1};
`;

const SwitcherLink = styled(SwitcherText)`
	color: ${Colors.primary.red};
	cursor: pointer;
`;
